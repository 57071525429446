@import 'bootstrap/less/variables';
@import '../_variables';

.promo-link {
  position: relative;
  height: 300px;
  padding: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: at(@colors, primary);
    opacity: 0.1;
    z-index: 0;
  }

  a,
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    height: 100%;
  }

  a {
    .mark-icon {
      margin-right: 10px;
    }
  }

  p {
    font-weight: bold;
    font-size: 1.3em;
  }

  @media (min-width: @screen-sm-min) {
    .container {
      position: relative;
      background: url(img/road.png) no-repeat 10% 100%;
      background-size: auto 100%;
      z-index: 1;
    }

    p {
       line-height: 0.8em;
       font-size: 2em;
     }
  }

  @media (min-width: @screen-md-min) {
    .container {
      background: url(img/road.png) no-repeat 20% 100%;
      background-size: auto 100%;
    }
  }
}
