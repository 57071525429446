@import 'bootstrap/less/variables';

.main-footer {
  padding-top: 30px;

  a {
    margin-right: 10px;
    text-decoration: underline;
  }

  .footer-menu {
    padding-top: 20px;
  }

  @media (min-width: @screen-sm-min) {
    .row {
      display: flex;
    }

    a {
      margin-right: 0;
      margin-left: 10px;
    }

    [class*=col-] {
      display: flex;
      align-items: flex-end;

      &.footer-menu {
        justify-content: flex-end;
        padding-top: 0;
      }
    }
  }
}