@import '../_variables';

a {
  &.text-light {
    &:focus,
    &:hover {
      color: at(@colors, light);
    }
  }

  &:focus,
  &:hover {
    text-decoration: none;
    opacity: 0.8;
    transition: opacity 0.3s;
    outline: none;
  }
}