.include-font(@family: arial, @weight: normal, @style: normal){
  @font-face{
    font-family: @family;
    src:url('fonts/@{family}-@{weight}.eot');
    src:url('fonts/@{family}-@{weight}.eot?#iefix') format('embedded-opentype'),
    url('fonts/@{family}-@{weight}.woff') format('woff'),
    url('fonts/@{family}-@{weight}.ttf') format('truetype');
    font-weight: @weight;
    font-style: @style;
  }
}
