html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1;
  }

  .main-footer {
    flex: 0;
  }
}

section {
  padding: 30px 0;

  h2 {
    margin-bottom: 30px;
  }
}

.no-margin {
  margin: 0;
}
