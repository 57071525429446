@import '_variables';

.for-each(@color in @colors) {
  @colorName: at(@color, 1);
  @colorValue: at(@color, 2);

  .bg-@{colorName} {
    background-color: @colorValue;
  }
  .text-@{colorName} {
    color: @colorValue;
  }
}
