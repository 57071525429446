.logo {
  img,
  strong {
    display: inline-block;
    vertical-align: middle;
  }

  p {
    font-size: 1.5em;
    line-height: 0.85em;
  }

  img {
    width: 50px;
    margin-right: 10px;
  }
}