@import '../_variables';
@import 'bootstrap/less/variables';

.image-links {
  padding-bottom: 150px;
  margin-bottom: 30px;
  background: url(img/car-bg.png) no-repeat bottom;
  background-size: 100% auto;

  .image-link-wrapper {
    border: 7px solid at(@colors, primary);
    display: flex;
    align-items: stretch;
    width: 155px;
    height: 155px;
    border-radius: 10px;
  }

  .image-link {
    display: flex;
    align-items: center;

    img {
      max-width: 100%;
    }

    &.swindonLink {
      padding: 10px;
    }
  }

  @media (min-width: @screen-sm-min) {
    padding-bottom: 250px;
  }

  @media (min-width: @screen-md-min) {
    padding-bottom: 350px;
  }
}
