@import '../_variables';

.mark-icon {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 60px;

  &:after,
  &:before {
    content: '';
    position: absolute;
  }

  &:after {
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border: 10px solid at(@colors, secondary);
    border-radius: 50%;
    background: at(@colors, light);
  }

  &:before {
    top: 29px;
    left: 2px;
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 27px solid at(@colors, secondary);
  }
}