@import 'bootstrap/less/variables';

header.main {
  padding: 10px 0 15px;

  .logo {
    display: inline-block;
    text-align: left;
    margin-bottom: 20px;
  }

  @media (min-width: @screen-sm-min) {
    .logo {
      margin-bottom: 0;
    }

    a {
      display: block;
      text-align: right;
    }
  }
}